import { Button } from 'startlibs/lib/components';
import { Link, withRouter } from 'react-router-dom';
import {connect} from 'react-redux'
import { getFetcher, formFetch } from 'startlibs';
import {
  withForm,
  Errors,
  Field,
  Form,
  TextInput,
  SimpleCheckbox
} from 'startlibs/lib/form';
import {withToggles} from 'startlibs/lib/hocs'
import React from 'react'
import jwt_decode from 'jwt-decode'
import styled from 'styled-components'

import {
  AdditionalActionButtons,
  Card,
  CardActionButton,
  CardHeader,
  LinkSSO,
  SignInLayout,
  SignInMessageBox
} from '../components/SigninLayout';
import {JwtAuthentication} from './JwtAuthentication'
import {PasswordInput} from '../components/PasswordInput'
import {RedefinePasswordForm} from './RedefinePassword'
import {SupportMessage} from '../components/SupportMessage'
import {TwoFactorAuthentication} from './TwoFactorAuthentication'
import {buildValidation, required, responseFailure} from '../lib/validation'
import {signIn} from '../reducers'

const DEFAULT_FORM = {rememberMe: false}

const SignInCard = styled(Card)`
  max-width: ${props => props.accountSettings ? `39rem` : `35rem`};
  margin: auto;
  transition: 0.25s ease;
`

const UNASSOCIATED_SYSTEM_ERROR = 'User user is not associated with a system.'
const INVALID_CREDENTIALS = 'Invalid credentials'

export const loginFailure =   ({detail, message, params, status}, passwordError = INVALID_CREDENTIALS) =>
  (status === 401 && detail === UNASSOCIATED_SYSTEM_ERROR && {'': [passwordError]}) ||
  (message === 'error.suspended' && {'': ["This account has been suspended. Please contact your administrator."]}) ||
  (message === 'error.locked' && {'': [<span>This account has been locked. Please check {params ? <b>{params.email}</b> : 'your email'} and click the link to unlock.</span>]}) ||
  (message === 'error.notActivated' && {'': [<span><b>Account not activated yet</b>. We just sent a new activation link to {params ? <b>{params.email}</b> : 'your email'}. <span className="error-detail">Please check your spam folder if necessary.</span></span>]}) ||
  ((message === 'error.validation' || message === 'error.http.401') && {'currentPassword': [passwordError]})


@withRouter
@withToggles('loading', 'accountSettings', 'expiredPassword', 'verify2fa')
@withForm(formFetch)
@connect(undefined, {signIn})
export class LoginForm extends React.Component {

  searchParams = new URLSearchParams(this.props.location.search)
  jwt = this.searchParams.has("jwt") ? jwt_decode(this.searchParams.get("jwt")) : {}

  onFailure = (...args) => {
    const {response} = args[1]
    if (response.status === 403) {
      this.passwordExpired(response.params.key)
    } else {
      responseFailure(loginFailure)(...args)
    }
  }

  passwordExpired = (key) => {
    this.props.verify2fa.close()
    this.props.expiredPassword.open(key)
  }

  onSuccess = (_, {idToken,id_token,username,preAuthToken}) => {
    if (username) {
      this.props.needsRegistration(idToken,username)
    } else if (preAuthToken) {
      this.props.verify2fa.open(preAuthToken)
    } else {
      this.concludeLogin(id_token)
    }
  }

  concludeLogin = (id_token) => {
    if (this.props.accountSettings.isOpen) {
      this.props.loading.open()
      getFetcher('/pasapi/account/info', null, {headers: new Headers({'Authorization': `Bearer ${id_token}`})})
        .then((result) => {
          this.props.signIn({...result, id_token})
          this.props.history.push('/account?systemId='+this.props.system.systemId)
        })
    } else {
      this.props.loading.open()
      window.location = this.props.system.systemWebHooks.systemAuthWebHook + id_token
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search)
    // if(this.props.system.systemWebHooks && this.props.system.systemWebHooks.migratedUrl){
    //   window.location.href = this.props.system.systemWebHooks.migratedUrl+this.props.location.search;
    // }
    if (urlParams.get("loginFailure")==='true' || urlParams.get('newRegistration')) {
      this.props.form.utils.setErrors({"password":[INVALID_CREDENTIALS]})
    }
    if (urlParams.get("username")) {
      this.props.form.utils.setValue('username',urlParams.get("username"))
    }
    if (urlParams.get("preAuthToken")) {
      this.props.verify2fa.open(urlParams.get("preAuthToken"))
    }
    this.props.form.utils.setValue('systemId',this.props.system.systemId)
    if (this.props.loginToSettings) {
      this.props.accountSettings.open()
    }
  }


  preValidation = buildValidation({
    password: [required],
    username: [required]
  },(props) => ({...props,systemId:this.props.system.systemId}))

  render() {
    const {system, form, loading,fromActivation, accountSettings, expiredPassword, loginToSettings, verify2fa, needsRegistration, location, autoLogin} = this.props
    const expiredSession = location.state && location.state.expiredSession

    if (verify2fa.isOpen) {
      return <TwoFactorAuthentication
        system={system}
        preAuthToken={verify2fa.isOpen}
        concludeLogin={this.concludeLogin}
        passwordExpired={this.passwordExpired}
        username={form.properties.username}
      />
    }

    if (this.jwt.sub && !expiredPassword.isOpen) {
      return <JwtAuthentication
        passwordExpired={this.passwordExpired}
        needsRegistration={needsRegistration}
        system={system}
        verify2fa={verify2fa}
        parsedJwt={this.jwt}
        jwt={this.searchParams.get("jwt")}
        autoLogin={autoLogin}
      />
    }

    const ssos = system.ssoEntities || []

    return (
      <SignInLayout hideSupportMessage system={system}>
        {
          fromActivation &&
          <SignInMessageBox>
            Email address successfully updated.
          </SignInMessageBox>
        }
        {
          expiredSession &&
          <SignInMessageBox warning>
            Your session has expired. Please sign in again.
          </SignInMessageBox>
        }
        {!expiredPassword.isOpen ?
          <SignInCard accountSettings={accountSettings.isOpen}>
            {accountSettings.isOpen ?
              <CardHeader>
                <h1>User authentication settings</h1>
                <p>Enter your credentials to manage your account</p>
              </CardHeader>
              :
              <CardHeader>
                <h1>Sign in to {system.systemName}</h1>
                <p>Enter your details below</p>
              </CardHeader>
            }
            <Form
              alwaysSave
              defaultProperties={DEFAULT_FORM}
              preValidation={this.preValidation}
              onSuccess={this.onSuccess}
              onFailure={this.onFailure}
              form={form}
              url='/pasapi/authenticate'
            >
              <TextInput
                autoComplete="off"
                tabIndex={1}
                label={system.labels.loginInput || "Username:"}
                placeholder="Enter your username"
                form={form}
                path="username"
              />
              <Field label="Password:">
                <Link className={(Object.keys(form.errors).length ? 'has-error ' : '') + 'password-link'} to="/recovery"
                      tabIndex={2}>Forgot password?</Link>
                <PasswordInput
                  autoComplete="off"
                  tabIndex={1}
                  form={form}
                  withoutField
                  path="password"
                  placeholder="Enter your password"
                />
              </Field>
              {false && !accountSettings.isOpen &&
              <div className="checkbox-list">
                <SimpleCheckbox
                  form={form}
                  label="Keep me signed in while in this computer"
                  path="rememberMe"
                />
              </div>}
              <Errors form={form}/>
              <CardActionButton highlight isLoading={form.isLoading || loading.isOpen} tabIndex={1}
                                type="submit">{accountSettings.isOpen ? 'Sign in to settings' : 'Sign in'}</CardActionButton>
              {ssos.map(sso => <LinkSSO href={sso.url}>{sso.label || "Use single sign-on (SSO) instead"}</LinkSSO>)}
            </Form>
          </SignInCard> :
          <ExpiredPassword system={system} tokenKey={expiredPassword.isOpen}/>
        }
        <SupportMessage system={system}/>
        {!expiredPassword.isOpen && !loginToSettings &&
        <AdditionalActionButtons>
          <Button outline small onClick={accountSettings.toggle}>{accountSettings.isOpen ?
            <span>Back to sign in</span> : 'Authentication settings'}</Button>
        </AdditionalActionButtons>
        }
      </SignInLayout>
    )
  }
}


const ExpiredPassword = (props) => <RedefinePasswordForm
  {...props}
  header={() =>
    <CardHeader>
      <h1>Your password has expired</h1>
      <p>Please set a new password to continue</p>
    </CardHeader>
  }
/>
