import React from 'react'
import {hot} from 'react-hot-loader'
import {Switch, Redirect, Route, withRouter} from 'react-router-dom'
import {ThemeProvider, css} from 'styled-components'
import {Fill, Slot, closePopups, formFetch, StartlibsProvider, getFetcher, postFetcher} from 'startlibs'
import {connect} from 'react-redux'
import {getColor} from 'startlibs/lib/lib'
import {Button, Loading, Dialog, Icon} from 'startlibs/lib/components'
import {withToggles} from 'startlibs/lib/hocs'
import {matchPath} from 'react-router'
import {PageLoader, ErrorBoundary} from 'startlibs/lib/components'
import styled from 'styled-components'
import {Errors, IconRadioBox} from 'startlibs/lib/form'
import {PageError} from './PageError'
import {lighten} from 'polished'
import {bearerHeader, clearNotifications, TEXT_HTML} from './reducers'
import {RegistrationForm} from './pages/RegistrationForm'
import {LoginForm} from './pages/LoginForm'
import {ActivationSuccess} from './pages/ActivationSuccess'
import {AccountSettings} from './pages/AccountSettings'
import {PasswordRecovery} from './pages/PasswordRecovery'
import {RedefinePassword} from './pages/RedefinePassword'
import {LinkExpiredError} from './errors/LinkExpiredError'
import {getJWTPayload} from './lib/utils'
import {DeletedAccountError} from './errors/DeletedAccountError'
import jwt_decode from 'jwt-decode'
import {JwtRegistrationForm} from './pages/JwtRegistrationForm'
import { SsoLogin } from './pages/SsoLogin'

export const LoadingUser = styled(({className}) => <div className={className}>
  <Loading size={40} borderWidth={6}/>
</div>)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  * {
    margin-left: auto;
    margin-right: auto;
  }
  img {
    max-width: 11rem;
    margin-bottom: 4rem;
  }
`

const firstSignInFormProperties = {physicianType: 'referring', specializations: []}

const PurviewPageLoader = styled(PageLoader)`
  background: #00D2FF;
`

const doNotRedirect = ['/signUp', '/recovery', '/redefinePassword', '/signIn']

const PAS_PAYLOAD_PARAM_NAME = 'pasAuthRequestPayload'
const PAS_SYSTEM_ID_PARAM = 'systemId'
const TOKEN_PARAM = 'token'
const AUTO_LOGIN = 'autoLogin'

@withToggles('hasFetchedUser', 'originalLocation')
@withRouter
@connect(({appUI, user}) => ({
  openPopup: appUI.openPopup,
  user
}), {closePopups /*getAccount*/})
class App extends React.Component {

  constructor(props) {
    super(props)
    this.originalLocation = props.location.pathname
    this.state = {fetchingSystem: true, updatedUrlParams: ''}
  }

  static startlibsConfig = {
    confirmDialogLabels: {
      title: 'Your changes will not be saved',
      content: 'The changes made in this screen were not saved. Would you like to quit anyway?',
      confirm: 'Save Changes',
      discard: 'Discard Changes',
      cancel: 'Cancel'
    },
    labels: {
      EditableBox: {
        save: 'Save',
        saveNew: 'Save',
        cancel: 'Cancel'
      }
    },
    FormUploadingImageError: 'The image is being uploaded, please wait a monent.',
    ErrorDialog: {
      title: 'This content could not be loaded',
      content: <React.Fragment>Please refresh the page and try again. <br/>If the problem persists, contact
        us.</React.Fragment>,
      close: 'Close'
    },
    fetchRoutes: []
  }

  handleWindowScroll = () => {
    const classes = document.body.className.split(' ')
    if (!window.pageYOffset !== (classes.indexOf('scrolled') < 0)) {
      const className = classes.filter(_ => _ !== 'scrolled').join(' ')
      document.body.className = className + (window.pageYOffset ? ' scrolled' : '')
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleWindowScroll)
    const urlParams = new URLSearchParams(this.props.location.search)
    // if (urlParams.get(PAS_PAYLOAD_PARAM_NAME) || urlParams.get(PAS_SYSTEM_ID_PARAM) || urlParams.get(TOKEN_PARAM)) {
    this.getSystem()
    /*} else {
      this.redirectToDefaultSystem()
    }*/
  }

  redirectToDefaultSystem() {
    fetch(`/pasapi/system/1/auth-request`)
      .then(e => e.text())
      .then(r => window.location.href = `?${PAS_PAYLOAD_PARAM_NAME}=${r}`)
  }

  getNewRegistrationUsername = () => {
    const urlParams = new URLSearchParams(this.props.location.search)
    if (urlParams.get('newRegistration') === 'true') {
      const payload = urlParams.get(PAS_PAYLOAD_PARAM_NAME)
      const username = urlParams.get('username')
      return getFetcher(`/pasapi/account/is-valid?${PAS_PAYLOAD_PARAM_NAME}=${payload}&username=${username}`)
        .then(({is_valid}) => is_valid !== "true" && username)
        .catch(() => false)
    } else {
      return Promise.resolve(false)
    }
  }

  getAutoRegistration = () => {
    const urlParams = new URLSearchParams(this.props.location.search)
    if (urlParams.get("registerToken")) {
      const login = JSON.parse(atob(urlParams.get("registerToken")))

      return postFetcher('/pasapi/authenticate',{...login,rememberMe:false,systemId:urlParams.get("systemId")})
        .then(({idToken,username,email,firstName,lastName}) => {
          if (username && idToken) {
            return {needsRegistration:idToken,newRegistration:true, registrationValues:{email,firstName,lastName,username}}
          } else {
            return {}
          }
        }).catch(() => {})
    } else if (urlParams.get("registerjwt")) {
      const jwt = urlParams.get("registerjwt")
      return fetch('/pasapi/validate-register-token',bearerHeader(jwt,TEXT_HTML))
        .then(({status}) => ({needsJwtRegistration:{jwt,validationFailed:status===401 || status === 403}}))
        .catch(() => ({needsJwtRegistration:{validationFailed:true,jwt}}))
    } else {
      return Promise.resolve({})
    }
  }

  getSystem = () => {
    const urlParams = new URLSearchParams(this.props.location.search)
    const payload = urlParams.get(PAS_PAYLOAD_PARAM_NAME)
    const token = urlParams.get(TOKEN_PARAM)
    const tokenPayload = getJWTPayload(token)
    const pathSystemId = window.location.pathname.indexOf("/auth")===0 ? window.location.pathname.replace(/\/auth\/?([^\/]*)\/?.*/,"$1") : ""
    const system = tokenPayload && tokenPayload.systemId ? tokenPayload.systemId : urlParams.get(PAS_SYSTEM_ID_PARAM)
    const autoLogin = urlParams.get(AUTO_LOGIN)

    const url =
      payload
        ? `/pasapi/system?${PAS_PAYLOAD_PARAM_NAME}=${payload}`
        : (system || pathSystemId)
        ?  `/pasapi/system/${system || pathSystemId}`
        : `/pasapi/systemref`
    urlParams.delete(TOKEN_PARAM)

    if (window.location.pathname.indexOf("/auth")!==0 && window.location.pathname.indexOf("/login")!==0) {
      window.history.replaceState('', null, this.props.location.pathname + '?' + urlParams.toString())
    }

    Promise.all([
        this.getNewRegistrationUsername(),
        getFetcher(url),
        this.getAutoRegistration()
      ])
      .then(([newRegistrationForUsername, {errors, ...system},login]) => {
        if (errors) {
          this.setState({systemFetchError: errors, fetchingSystem: false})
        } else {
          if (urlParams.has(PAS_PAYLOAD_PARAM_NAME)) {
            urlParams.delete(PAS_PAYLOAD_PARAM_NAME)
            urlParams.set(PAS_SYSTEM_ID_PARAM,system.systemId)
            window.history.replaceState('', null, this.props.location.pathname + '?' + urlParams.toString())
          }
          document.title = system.systemName
          this.setState({updatedUrlParams: urlParams, system, token, systemFetchError: false, fetchingSystem: false, autoLogin, newRegistrationForUsername,...login})
        }
      })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleWindowScroll)
  }

  static theme = {
    startlibs: {
      Button: css`
          box-shadow: inset 0 -3px 0 0 rgba(0,0,0,0.15);
          border-radius: 5px;
          ${props => props.small && `
            box-shadow: inset 0 -2px 0 0 rgba(0,0,0,0.15);
          `}
      `,
      ContextMenu: `
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
        border-radius: 5px;
      `,
      Popup: `
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.4);
        border-radius: 4px;
      `,
      ListItem: css`
        ${props => props.hover && css`
          & > a, & > label {
            background-color: ${getColor('main')};
            color: white;
          }
        `}
        ${props => props.highlight && css`
            color: ${getColor('main')};
            font-weight: 600;
        `}
      `,
      Dialog: css`
        background-color: white;
        border-radius: 6px;
        ${Dialog.Content} {
          border-top: none;
        }
        ${Dialog.Footer} {
          border-top: none;
        }
        ${Dialog.DialogIcon} {
          font-size: 100px;
        }
      `,
      FieldLabel: `
        font-weight: 600;
      `,
      TextInput: `
        border-radius: 5px;
      `,
      IconRadioBox: css`
        border-radius: 5px;
        ${Icon} {
          font-size: 120px;
          line-height: 56px;
        }
        ${IconRadioBox.Text} {
          padding: 1rem;
          box-shadow: inset 0 -3px 0 0 rgba(0,0,0,.15);
        }
      `,
      ErrorItem: css`
        background: ${getColor('alert')};
        color: white;
        border: 0;
        ${Errors.CloseIcon} {
          color: white;
          background-color: rgba(0, 0, 0, 0.2);
          width: 20px;
          height: 20px;
          line-height: 20px;
          &:hover {
            background: rgba(0,0,0,0.25);
          }
        }
        a {
          color: white;
          font-weight: 600;
        }
      `
    },
    colors: {
      gray30: '#1e1e1e',
      gray60: '#3c3c3c',
      gray90: '#5a5a5a',
      gray120: '#787878',
      gray150: '#969696',
      gray180: '#b4b4b4',
      gray210: '#d2d2d2',
      gray240: '#f0f0f0',
      main: '#008bd2',
      secondary: '#28AAE1',
      alert: '#C3282D',
      warning: '#e6932e',
      success: '#39B54A',
      physician: '#00a99d',
      organization: '#ed1e79'
    },
    iconFont: 'pix-icons',
    icons: {
      'note': '\ue935',
      'delete': '\ue934',
      'help': '\ue933',
      'download': '\ue930',
      'print': '\ue931',
      'email': '\ue932',
      'arrow-up-line': '\ue92c',
      'arrow-down-line': '\ue92d',
      'arrow-left-line': '\ue92e',
      'arrow-right-line': '\ue92f',
      'hlogo-right': '\ue929',
      'hlogo-left': '\ue92a',
      'hlogo-center': '\ue92b',
      'reload': '\ue927',
      'image': '\ue928',
      'external-link-line': '\ue923',
      'edit': '\ue924',
      'reorder': '\ue925',
      'move': '\ue926',
      'bold': '\ue91b',
      'italic': '\ue91c',
      'underline': '\ue91d',
      'strikethrough': '\ue91e',
      'insertUnorderedList': '\ue91f',
      'insertOrderedList': '\ue920',
      'outdent': '\ue921',
      'indent': '\ue922',
      'sort': '\ue91a',
      'zip-file': '\ue919',
      'calendar': '\ue917',
      'lock': '\ue918',
      'directories': '\ue913',
      'files': '\ue914',
      'failure': '\ue915',
      'external-link': '\ue916',
      'plus': '\ue910',
      'edit-box': '\ue911',
      'sign-out': '\ue912',
      'share': '\ue90a',
      'arrow-down': '\ue903',
      'arrow-up': '\ue904',
      'arrow-left': '\ue905',
      'arrow-right': '\ue906',
      'upload': '\ue907',
      'view': '\ue908',
      'settings': '\ue909',
      'search': '\ue90b',
      'check': '\ue90c',
      'x': '\ue90d',
      'plus-circle': '\ue90e',
      'notification': '\ue90f',
      'user': '\ue900',
      'physician': '\ue901',
      'organization': '\ue902',
      'report': '\ue936',
      'checked-report': '\ue937',
      'station': '\ue938',
      'warning': '\ue939',
      'premium': '\ue93a',
      'credit-card': '\ue93b',
      'clock': '\ue93d',
      'zoom-in': '\ue93e',
      'zoom-out': '\ue93f',
      'minus': '\ue941',
      'plus': '\ue910',
      'home-line': '\ue940',
      'home': '\ue942',
      'hide': '\ue943',
    }
  }


  closePopups = () => {
    if (this.props.openPopup) {
      this.props.closePopups()
    }
  }

  render() {
    const {updatedUrlParams, system, systemFetchError, fetchingSystem, autoLogin, needsRegistration, needsJwtRegistration, newRegistration, newRegistrationForUsername, registrationValues, token} = this.state
    const {location, user} = this.props
    // In order to deal with AWS migrated ViVA customers acessing legacy PAS address
    // Redirect to the new URL with paths and updated params (dealing with payload for system discover)
    if(system && system.systemWebHooks && system.systemWebHooks.migratedUrl){
      window.location.href = system.systemWebHooks.migratedUrl+location.pathname+location.search;
      // window.location.href = system.systemWebHooks.migratedUrl+location.pathname+'?'+updatedUrlParams.toString();
    }
    return (
      <StartlibsProvider config={App.startlibsConfig}>
        <ThemeProvider theme={App.theme}>
          <div className="App" onClick={this.closePopups}>
            <Notifications/>
            <PurviewPageLoader/>
            {
              (fetchingSystem && <LoadingUser/>) ||
              (systemFetchError && <SystemFetchError error={systemFetchError} retry={this.getSystem}/>) ||
              (needsRegistration && <RegistrationForm newRegistration={newRegistration} idToken={needsRegistration} registrationValues={registrationValues} system={system}/>) ||
              (needsJwtRegistration && <JwtRegistrationForm jwt={needsJwtRegistration.jwt} expired={needsJwtRegistration.validationFailed} system={system}/>) ||
              (newRegistrationForUsername &&
                <RegistrationForm newRegistrationForUsername={newRegistrationForUsername} system={system}/>) ||
              (!needsRegistration &&
                <Switch>
                  {user.email && <Route path="/account" render={() => <AccountSettings system={system}/>}/>}
                  <Route path="/activate-success" render={() => <ActivationSuccess token={token} system={system}/>}/>
                  <Route path="/recovery" render={() => <PasswordRecovery system={system}/>}/>
                  <Route path="/redefine-password" render={() => <RedefinePassword system={system}/>}/>
                  <Route path="/reset" render={() => <RedefinePassword system={system} autoLogin={autoLogin}/>}/>
                  <Route path="/2fa" render={() => <LoginForm system={system}/>}/>
                  <Route path="/settings" render={() => <LoginForm loginToSettings system={system}/>}/>
                  <Route path="/error/linkexpired" render={() => <LinkExpiredError system={system}/>}/>
                  <Route path="/error/deleted-account" render={() => <DeletedAccountError system={system}/>}/>
                  <Route path="/change-email-success" render={() => <LoginForm fromActivation system={system}/>}/>
                  <Route path="/" exact key={location.state && location.state.loginKey} render={() => <LoginForm 
                    needsRegistration={(idToken,username) => this.setState({needsRegistration: idToken, registrationValues: {username}})} system={system}/>}/>
                  <Route path="/sso" exact key={location.state && location.state.loginKey} render={() => <SsoLogin 
                    needsRegistration={(idToken,username) => this.setState({needsRegistration: idToken, registrationValues: {username}})} system={system}/>}/>
                  <Route path="/auth/:systemId" key={location.state && location.state.loginKey} render={() => <LoginForm
                    needsRegistration={(idToken,username) => this.setState({needsRegistration: idToken, registrationValues: {username}})} system={system}/>}/>
                  <Route path="/auth" key={location.state && location.state.loginKey} render={() => <LoginForm
                    needsRegistration={(idToken,username) => this.setState({needsRegistration: idToken, registrationValues: {username}})} system={system}/>}/>
                  <Route path="/login" key={location.state && location.state.loginKey} render={() => <LoginForm
                    needsRegistration={(idToken,username) => this.setState({needsRegistration: idToken, registrationValues: {username}})} system={system} autoLogin={autoLogin}/>}/>
                  <Redirect to={"/"+(system ? '?systemId='+system.systemId : '')}/>
                </Switch>)
            }
            <div style={{position: 'absolute', top: 0, left: 0, right: 0, zIndex: 100}}>
              <Slot name="Popup"/>
            </div>
            <div style={{position: 'absolute', top: 0, left: 0, right: 0, zIndex: 200}}>
              <Slot name="Portal"/>
              <Slot name="Dialog"/>
              <Slot name="Confirm-Dialog"/>
              <Slot name="Notification"/>
              <Slot name="Notifications"/>
            </div>
          </div>
        </ThemeProvider>
      </StartlibsProvider>
    )
  }
}

const SystemFetchError = ({error, retry}) => <div>
  An error has occurred <Button onClick={retry}>Retry</Button>
</div>

@withToggles('toggle')
@connect(({appUI}) => ({
  notifications: appUI.notifications
}), {clearNotifications})
class Notifications extends React.Component {

  componentDidUpdate(oldProps) {
    if (oldProps.notifications !== this.props.notifications && this.props.notifications.length) {
      this.props.toggle.open()
      setTimeout(this.props.toggle.closeAfter(4000, 2000), 100)
    }
    if (!this.props.toggle.isOpen && oldProps.toggle.isOpen) {
      this.props.clearNotifications()
    }
  }

  render() {
    const {toggle, notifications} = this.props
    const notification = notifications[0]
    return toggle.isOpen && notification && (<Fill name="Notifications">
      <div className={"system-notification "+(notification.status || "success")}>
        {notification.value || notification}
        <a className="icon icon-x" onClick={toggle.close}/>
      </div>
    </Fill>)
  }
}

export default hot(module)(App)
