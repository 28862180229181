import React from 'react'
import styled from 'styled-components'
import {Link, withRouter} from 'react-router-dom'
import {withToggles} from 'startlibs/lib/hocs'
import {Errors, Field, Form, SimpleCheckbox, TextInput, withForm} from 'startlibs/lib/form'
import {getFetcher, postFetcher} from 'startlibs'
import connect from 'react-redux/es/connect/connect'
import {formFetchWithAuthorization, signIn} from '../reducers'
import {buildValidation, confirmEmails, confirmPasswords, responseFailure} from '../lib/validation'
import {Button, SplitColumnsContainer} from 'startlibs/lib/components'
import {
  SignInLayout,
  Card,
  CardHeader,
  FieldRequirements,
  BelowFieldDescription,
  AdditionalInfo,
  CardActionButton
} from '../components/SigninLayout'
import {loginFailure} from './LoginForm'
import {PasswordInput} from '../components/PasswordInput'
import {ActivationSuccess} from './ActivationSuccess'

const getErrorForField = (field) => {
  if (field === 'password') {
    return {password: []}
  }
  if (field === 'email') {
    return {email: ['Invalid email address.']}
  }
}

export const allRequired = (s, k) => !s && 'All fields are required.'

const TextInputMaybeLocked = ({value,form,path,...rest}) =>
  value
  ? <TextInput
      {...rest}
      value={value}
      disabled
      locked
    />
  : <TextInput
      {...rest}
      tabIndex={1}
      form={form}
      path={path}
    />

@withRouter
@withToggles('success','loading', 'visiblePassword', 'activated')
@withForm(formFetchWithAuthorization)
@connect(undefined, {signIn})
export class RegistrationForm extends React.Component {

  onFailure = responseFailure(
    ({detail, type, message, fieldErrors}) =>
      ((message === 'error.validation' || message === 'error.http.401') && Array.isArray(fieldErrors) && fieldErrors.reduce((acc, {field}) => ({...acc, ...getErrorForField(field)}), {})) ||
      (type === 'https://pas.purview.net/problem/invalid-password' && {password: []}) ||
      (message === 'error.emailexists' && {email: ['This email is already registered']})
  )

  onSuccess = (_,{id_token}) => {
    if (id_token) {
      this.props.activated.open(id_token)
    } else {
      localStorage.setItem('hasLogged', 'true')
      const url = this.props.originalLocation
      this.props.success.open()
    }
  }

  preValidation = buildValidation({
    password: [allRequired, confirmPasswords('newPassword')],
    firstName: [allRequired],
    lastName: [allRequired],
    newPassword: [allRequired],
    email: [allRequired, confirmEmails('confirmEmail')],
    ...(this.props.newRegistrationForUsername ? {currentPassword: [allRequired]} : {})
  })


  handleSubmit = (e) => {
    e.preventDefault()
    if (this.props.newRegistrationForUsername) {
      this.props.loading.open()
      postFetcher('/pasapi/authenticate', {
        username: this.props.newRegistrationForUsername,
        password: this.props.form.properties.currentPassword,
        systemId: this.props.system.systemId,
        rememberMe:false
      })
        .then(({idToken}) => {
          this.props.form.utils.submitProperties(this.props.form.properties,{id_token:idToken})
          this.props.loading.close()
        })
        .catch(({errorResponse}) => {
          this.props.form.utils.setErrors(loginFailure(errorResponse,"The current password is incorrect."))
          this.props.loading.close()
        })
    } else {
      this.props.form.utils.submitProperties()
    }
  }

  componentDidMount() {
    if (this.props.registrationValues) {
      this.props.form.utils.setValues((chain) => chain.assign({...this.props.registrationValues,confirmEmail:this.props.registrationValues.email}))
    }
  }

  render() {
    const {system, activated, form, success, idToken, visiblePassword, newRegistration, newRegistrationForUsername, loading, registrationValues} = this.props
    const {username,email,firstName,lastName} = registrationValues || {}
    const signupFromOtherMeans = username && !!email
    if (activated.isOpen) {
      return <ActivationSuccess token={activated.isOpen} system={system} newRegistration/>
    }
    return <SignInLayout system={system}>
      {!success.isOpen ?
       <Card>
         <CardHeader>
           <h1>{signupFromOtherMeans ? "Registration" : newRegistration ? 'User registration' : 'User registration update'}</h1>
           {
             newRegistration
            ? <p>Please fill in the fields below to register your account.</p>
            : <p>Please fill in the fields below to enable <b>enhanced authentication security</b> for your account.</p>
           }
         </CardHeader>
         <Form
           alwaysSave
           preValidation={this.preValidation}
           onSuccess={this.onSuccess}
           onFailure={this.onFailure}
           onSubmit={this.handleSubmit}
           form={form}
           id_token={idToken}
           url='/pasapi/register'
         >
           <TextInput
             label="Username"
             value={username || newRegistrationForUsername}
             locked
             disabled
           />
           <SplitColumnsContainer>
             <TextInput
               tabIndex={1}
               label="First name"
               form={form}
               path="firstName"
             />
             <TextInput
               tabIndex={1}
               label="Last name"
               form={form}
               path="lastName"
             />
           </SplitColumnsContainer>
           <SplitColumnsContainer>
             <TextInputMaybeLocked
               value={email}
               tabIndex={1}
               label="Email"
               form={form}
               path="email"
             />
             <TextInputMaybeLocked
               value={email}
               tabIndex={1}
               onPaste={(e)=>e.preventDefault()}
               onCopy={(e)=>e.preventDefault()}
               label="Confirm email"
               form={form}
               path="confirmEmail"
             />
          </SplitColumnsContainer>
          <BelowFieldDescription>Please enter a valid email address. We will send you a link to activate your account.</BelowFieldDescription>
           {
             newRegistrationForUsername &&
             <PasswordInput
               tabIndex={1}
               form={form}
               path="currentPassword"
               label="Current password"
             />
           }
           <SplitColumnsContainer>
             <Field label={signupFromOtherMeans ? "Create password" : "New password"}>
               <PasswordInput
                 tabIndex={1}
                 form={form}
                 path="password"
                 syncVisible={visiblePassword}
                 withoutField
               />
             </Field>
             <Field label="Confirm new password">
               <PasswordInput
                 tabIndex={1}
                 onPaste={(e)=>e.preventDefault()}
                 form={form}
                 path="newPassword"
                 syncVisible={visiblePassword}
                 withoutField
               />
             </Field>
           </SplitColumnsContainer>
           <FieldRequirements hasErrors={!!form.errors['password']}>
             Your new password must be <b>{system.systemAuthPolicy.pwdMinimumLength} or more characters</b>, containing at least <b>one uppercase letter</b>, one <b>number</b> and one <b>special character</b>
           </FieldRequirements>
           <Errors form={form}/>
           <CardActionButton
             isLoading={form.isLoading || loading.isOpen}
             className="highlight with-loader"
             tabIndex={1}
             type="submit"
             highlight
           >
             {newRegistration  ? "Register" : "Save" }
           </CardActionButton>
         </Form>
       </Card> :
       <Card>
         <CardHeader>
           <h1>Almost there! Check your email</h1>
         </CardHeader>
         <p>We have e-mailed you a link to activate your account.</p>
         <p>Please check <b>{form.properties.email}</b> and click the activation link to finish your registration
           update.</p>
         <AdditionalInfo>If you do not find the email in your inbox, please check your spam or junk
           folder.</AdditionalInfo>
       </Card>}
    </SignInLayout>
  }
}
